<template>
  <div id="bridgepayDeposit">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="form_deposit">
          <h4>{{ $t('deposit.cc.header') }}</h4>
          <div class="content" v-if="formFlag">
            <div class="info_box">
              <img src="@/assets/images/channel/visa.png" alt="" />
              <p>{{ $t('deposit.cardpay.desc') }}</p>
              <ul>
                <li>{{ $t('deposit.cardpay.inst1') }}</li>
                <li>{{ $t('deposit.cardpay.inst2') }}</li>
                <li>{{ $t('deposit.cardpay.inst3') }}</li>
                <li>{{ $t('deposit.method.inst4', { number: '4' }) }}</li>
              </ul>
              <div v-if="whiteListRegulator.includes($store.state.common.regulator)">
                <p v-html="$t('deposit.cardpay.note', { mail: GLOBAL_CONTACT_EMAIL })"></p>
              </div>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.cardpay.form.header') }}</p>
              <el-form label-position="top" :model="bridgeForm" ref="bridgeForm" status-icon :rules="bridgeRules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        :supportedCurrenciesList="supportedCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="bridgeForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                        <el-input v-model="bridgeForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <el-button
                  type="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitForm()"
                  data-testid="submit"
                >
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
          <div id="main"></div>
        </div>
      </div>
    </div>
    <vDialog :visible.sync="dialogVisible">
      <p>{{ $t('deposit.cc.mastercardonly') }}</p>
      <el-button @click="dialogVisible = false">{{ $t('supportTickets.close') }}</el-button>
    </vDialog>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import { apiBridge_payment } from '@/resource';
import vDialog from '@/components/home/vDialog';
import { countryCodeEnum } from '@/constants/country';
export default {
  name: 'BridgepayDeposit',
  components: { NumericInput, AccountNumber, vDialog },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      bridgeForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      bridgeRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      formFlag: true,
      whiteListRegulator: ['vfsc'],
      supportedCurrencies: ['USD', 'AUD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD'],
      dialogVisible: false,
      specifiedCountries: [countryCodeEnum.JAPAN]
    };
  },
  watch: {
    accountCurrency: {
      handler: function (val, oldVal) {
        if (val == 'USD') this.maxLimit = 1000000;
        else this.maxLimit = 99999999.99;
      },
      deep: true
    }
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    }
  },
  mounted() {
    this.dialogVisible = this.specifiedCountries.includes(this.countryCode);
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.bridgeForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiBridge_payment(
        {
          mt4Account: this.bridgeForm.accountNumber,
          operateAmount: this.bridgeForm.amount,
          applicationNotes: this.bridgeForm.notes,
          paymentMethod: 'bridge'
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['bridgeForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                this.formFlag = false;
                this.showBridgerpay(result.data.data);
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));

                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    },
    showBridgerpay(data) {
      const dataJson = JSON.parse(data);
      let script = document.createElement('script');
      script.setAttribute('src', dataJson.url);
      script.setAttribute('data-cashier-key', dataJson.key);
      script.setAttribute('data-theme', 'transparent');
      script.setAttribute('data-cashier-token', dataJson.token);
      document.getElementById('main').appendChild(script);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
.note {
  margin-bottom: 30px;
  color: #fff;

  /deep/ a {
    color: #fff;
    text-decoration: underline;
  }
}
</style>
